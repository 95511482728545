.calendar-wrap{
  // padding: 90px 40px 40px 40px;
  height: 1000px;
}

.fc .fc-view-harness {
  background-color: #ffffffa0;
}

.fc-col-header-cell-cushion {
  color: #000;
}

a {
  color: #000;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  white-space: pre-wrap;
}
