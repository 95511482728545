.button{
  background-color: #ea6979;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  text-align: center;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: #fff;
    color: #ea6979;

    span{
      color: #ea6979;
    }
  }

  &:focus{
    background-color: #fff;
    color: #ea6979;

    span{
      color: #ea6979;
    }
  }
}

.button-alt{
  background-color: white;
  color: #ea6979;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  text-align: center;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: #ea6979;
    color: #fff;

    span{
      color: #fff;
    }
  }

  &:focus{
    background-color: #ea6979;
    color: #fff;

    span{
      color: #fff;
    }
  }
}

.button-red{
  background-color: #d81414;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  text-align: center;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: white;
    color: #d81414;

    span{
      color: #d81414;
    }
  }

  &:focus{
    background-color: white;
    color: #d81414;

    span{
      color: #d81414;
    }
  }
}

button{
  padding: 5px 15px;
  border: none;
  
  &:focus{
    background-color: transparent;
  }
}

.event-add{
  display: flex;
  align-items: center;
  color: #000000;
  background: none;
  border: none;
  
  p{
    font-weight: bold;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}

.button-icon-right{
  display: flex;
  align-items: center;
  color: #000000;
  background: none;
  border: none;
  margin-left: 30px;
  
  p{
    font-weight: bold;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
}

.btn-icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);
  color: #000000;
  background-color: #fff;
  transition: all 0.3s ease-in-out;

  span{
    color: #000000;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
  }

  &:hover{
    background-color: #ea6979;
    color: #fff;

    span{
      color: #fff;
    }
  }

  &:focus{
    background-color: #ea6979;
    color: #fff;

    span{
      color: #fff;
    }
  }
}

.ais-SearchBox-reset{
  background-color: transparent;
  border: none;
  margin: 0;
}

.back-btn{
  margin-top: 50px;
}

.disabledBtn{
  -webkit-box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3); 
  box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3);
  color: #afafaf;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  span{
    color: #afafaf;
  }
}

.ais-InfiniteHits-loadMore{
  position: absolute;
  left: 260px;
  margin-top: 10px;
  padding: 6px 12px;
  border: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);
  color: #ea6979;
  background-color: #fff;
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: #ea6979;
    color: #fff;
  }

  &:focus{
    background-color: #ea6979;
    color: #fff;
  }
}

.ais-InfiniteHits-loadMore--disabled{
  position: absolute;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3); 
  box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3);
  color: #afafaf;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.ais-InfiniteHits-loadPrevious{
  position: absolute;
  margin-top: 10px;
  padding: 6px 12px;
  border: 0px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);
  color: #ea6979;
  background-color: #fff;
  transition: all 0.3s ease-in-out;

  &:hover{
    background-color: #ea6979;
    color: #fff;
  }

  &:focus{
    background-color: #ea6979;
    color: #fff;
  }
}

.ais-InfiniteHits-loadPrevious--disabled{
  position: absolute;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3); 
  box-shadow: 0px 0px 13px -1px rgba(109, 109, 109, 0.3);
  color: #afafaf;
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.editImg{
  position: absolute;
  bottom: 0;
  border-radius: 0px 25px 0px 25px;
  padding: 10px 20px 7px 20px;
  color: #ea6979;
  background-color: white;
  transition: all 0.3s ease-in-out;

  &:hover{
    color: white;
    background-color: #ea6979;
  }
}

.button-side{
  width: 45%;
  display: flex;
  justify-content: space-between;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -40px;
}
