.head-content, .main-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.head-subcontent{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.main-content{
  align-items: flex-start;

  >.extra-inputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    >.info-item{
      margin-right: 40px;
    }
  }

  .info-container{
    width: 100%;

    h6{
      margin-top: 40px;

      &:first-child{
        margin-top: 0;
      }
    }

    .info-section{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &:last-child{
        margin-bottom: 50px;
      }

      .info-item{
        width: 20%;
        height: 55px;
        margin: 10px 0;
      }

      .info-item-big{
        width: 100%;
        height: auto;
        margin: 10px 0;
      }
    }
  }
}

.info-title{
  background-color:#ea6979;
  color: white;
  width: 100%;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30)
}

.show-times{
  margin: 50px 0 100px 0;
}

.info-item{
  margin: 40px 0;
}

.info-item label{
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  font-size: 20px;
}

.info-item-big label{
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  font-size: 20px;
}

.info-item p{
  margin: 0;
  font-size: 16px;
}

.i-icons{
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  a{
    cursor: pointer;
    height: inherit;
    margin: 10px;
    color: #000 !important;
  }
}

td{
  padding: 0px 5px;
}

.actions{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &>.utility-bar{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .first-row{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      form{
        flex-direction: row;
        padding: 0;
        margin-right: 20px;
      }
    }

    .second-row{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.event-list{
  margin-bottom: 50px;
}

.notification-list{
  table.striped{
    tbody{
      td{
        height: 40px;
      }
    }
  }
}

#dateC{
  width: 15%;
  white-space: pre-wrap;
}

#orgC{
  width: 10%;
}

.status-block{
  padding: 5px 10px;
  text-align: center;
  &.confirmed{
    background-color: rgba(9, 223, 38, 0.6);
  }
  &.concept{
    background-color: rgba(218, 212, 20, 0.6);
  }
  &.cancelled{
    background-color: rgba(223, 70, 9, 0.6);
  }
}

.ais-RefinementList{
  z-index: 0;
}

.ais-RefinementList-list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .ais-RefinementList-item{
    margin-right: 20px;
  }
}


@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .title{
    h1{
      font-size: 2.8rem;
    }
  }
  .actions{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .utility-bar{
      align-items: center;
      width: 100%;
      .first-row, .second-row{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    >div>.event-add{
      margin-top: 30px;
    }
  }

  .ais-SearchBox-form input[type=search]{
    width: 70vw;
    min-width: unset;
    margin-bottom: 20px;
  }

  .main-content{
    .info-container{
      .info-section{
        flex-direction: column;
        .info-item{
          width: 100%;
        }
      }
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .title{
    h1{
      font-size: 4.2rem;
    }
  }

  .actions{
    flex-direction: row;
    justify-content: space-between;
    align-items: unset;
    .utility-bar{
      align-items: flex-start;
      width: unset;
      .first-row, .second-row{
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
      }
    }
    >div>.event-add{
      margin-top: 0;
    }
  }

  .ais-SearchBox-form input[type=search]{
    width: unset;
    min-width: 450px;
    margin-bottom: 0px;
  }

  .main-content{
    .info-container{
      .info-section{
        flex-direction: row;
        .info-item{
          width: 25%;
        }
      }
    }
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
