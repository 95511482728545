.ais-InfiniteHits-item{
  transition: all ease-in-out 0.3s;
  &:hover{
    transform: scale(1.02);
    box-shadow: 0px 0px 37px -9px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 0px 37px -9px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 0px 37px -9px rgba(0,0,0,0.20);

    td{
      transition: all ease-in-out 0.3s;
      &:nth-child(2){
        font-weight: bold;
      }
    }
  }
}
