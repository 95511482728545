@import url('https://fonts.googleapis.com/css2?family=Ruda:wght@400;700&display=swap');
@import url('https://rsms.me/inter/inter.css');

@font-face{
  font-family: 'SingleSleeve';
  font-style: normal;
  font-weight: 200;
  src:  local('SingleSleeve'), 
        url(../../font/SingleSleeve.otf) format('opentype');
}

h1, h2, h3, h4, h5, h6, th{
  font-family: 'SingleSleeve', sans-serif;
  font-weight: 200;
  margin: 0;
  color: #000000;
  width: fit-content;
  letter-spacing: 1px;

  &.label{
    font-size: 15px;
  }
}

body, button, input, optgroup, select, textarea {
  font-family: 'Ruda', sans-serif;
}

h5{
  color: #a3a3a3;
}


.title{
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1{
    color: #000000;
  }
}

h6{
  font-size: 25px;
}

h6.label{
  font-size: 18px;
}

th{
  font-size: 25px;
  white-space: nowrap;
}


.info-title{
  font-size: 25px;
}

.handsontable span.colHeader, .handsontable span.rowHeader{
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color:rgba(0,0,0,.54);
}

.handsontable th, .handsontable td {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
}

.handsontable td{
  font-weight: 400;
}

