.pink.lighten-1{
  background-color: #ea6979 !important;
}

.account-detail{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;

  button{
    width: 100px;
    height: 100px;
    font-size: 25px;
  }

  .account-detail-info{
    margin-left: 20px;
  }
}

.account-dashboard{
  margin-top: 50px;
  
  .dashboard-form{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    p{
      width: 300px;
    }
  }

  .button{
    align-self: flex-start;
  }
}

.account-change{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;

  .account-change-email{
    width: 50%;

    form{
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .account-change-password{
    width: 50%;

    form{
      padding: 0;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}


@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .account-change{
    flex-direction: column;

    .account-change-email, .account-change-password{
      width: 100vw;
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .account-change{
    flex-direction: row;

    .account-change-email, .account-change-password{
      width: 50%;
    }
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
