.wide-table{
  width: 100%;
}

#hot-app{
  position: absolute;
  z-index: 99;
  width: 100%;
  left: 0;
}

.handsontable .htCheckboxRendererInput{
  opacity: 100%;
}

.handsontable tbody tr th{
  background-color: white;
}

.handsontable .relative{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.handsontable thead th .relative{
  padding: 0px 2px !important;
  justify-content: start;
}

.stagetitle{
  display: flex;
  width: 100%;

  &>h4{
    margin-left: 20px
  }

  .select-number{
    width: 50px;
  }
}
