.nav-wrapper{
  background-color: #ffffffda;
  color: #ea6979;
  position: fixed;
  border-radius: 40px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.3);

  .container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #ea6979;
    height: 65px;
    max-width: unset;
    width: unset;

    .navBtn-icon{
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #000;
      background-color: transparent;
    }
    .brand-logo{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: bold;
      color: #000;
      margin-left: 10px;

      .logo-extra{
        position: absolute;
        transform: scaleX(-1);
        width: 100px;
        top: -38px;
        left: -38px;
      }
    }
    .btn-floating{
      color: #000;
      background-color: #ea6979;
    }
    
    ul{
      margin: 0 10px;
    }
  }
}

nav ul a{
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    color: #b4b4b4 !important;
  }
}

nav .brand-logo{
  position: relative;
}

.help-block{
  background-color: white;
  position: absolute;
  top: 70px;
  padding: 20px;
  color: #303667;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.30);

  >p{
    margin-block-start: 0;
    margin-block-end: 0;
  }
  
  >a{
    transition: none;
    color: #000;
    padding: 0;
    cursor: default;

    &:hover{
      background-color: transparent;
    }
  }

  &.hidden{
    display: none;
  }
}





@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .nav-wrapper{
    .container{
      justify-content: center;
      .brand-logo{
        display: none;
      }
      ul{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .nav-wrapper{
    right: 0;
    width: auto;
    margin: 30px 8vw 0 8vw;
    .container{
      justify-content: flex-end;
      .brand-logo{
        display: flex;
      }
      ul{
        display: block;
        width: unset;
      }
    }
  }
}
