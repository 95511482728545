.header{
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 34px;
  box-shadow: 0px 0px 13px -1px rgba(97, 184, 255, 0.3);
  border-radius: 25px;
  
  .factuur-info{
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    height: 500px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 25px;

    &>h4{
      color: #ea6979;
      margin-bottom: 30px;
    }

    p{
      margin-block-start: 0em;
      margin-block-end: 0em;
    }

    a{
      color: #ea6979;
    }
  }

  .header-img{
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 25px;
  }
}

.KVM-big{
  width: 75%;
  margin-bottom: 20px;
}

.KVM-small{
  margin-left: 15px;
  margin-right: 20px;
  height: 50px;
}


@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .header{
    flex-direction: column;
    .factuur-info{
      position: relative;
      width: 100%;
    }
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .header{
    flex-direction: row;
    .factuur-info{
      position: absolute;
      width: 320px;
    }
  }
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 

}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  
}
