.notification-title{
  padding: 20px 0 10px 10px;
  position: fixed;
  background-color: white;
  width: 300px;
  -webkit-box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.3); 
  box-shadow: 0px 0px 13px -1px rgba(255, 204, 102, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificatie-items{
  padding: 60px 0 20px;
  .notificatie-item{
    padding: 10px;
  
    &>.event-add{
      align-items: unset;
      padding: 0;
      margin-top: 5px;
    }
  }
}
