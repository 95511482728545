

html{
  min-height: 100%;
}

table{
  width: unset;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 200;
  background-color: #d5f1ff;
  min-height: 100%;
}

p{
  margin-block-start: 0em;
  margin-block-end: 0em;
  line-height: initial;
}

.floatr{
  float: right;
  font-weight: bold;
}

.flex-container-height{
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.flex-container{
  display: flex;
  flex-direction: row;
  height: auto;
}

.flex-container-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 80px;
}

.container-small{
  padding-top: 80px;
  width: 50%;
}

.container-wide{
  width: 85%;
  margin: auto;
  padding-top: 80px;
}

.container-wide-split{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  width: inherit;
  margin: auto;

  .split-1, .split-2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
}

.hero-container{
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 50px;

  .hero-image{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 15px;
  }

  .hero-title{
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    &>h1{
      color: #d5f1ff;
      margin-left: 20px;
    }
    &>img{
      color: #d5f1ff !important;
    }
  }
}

.timetable-container{
  margin-top: 50px;
}

.greyed-out{
  color: grey;
}

.section{
  padding-top: 80px;
}

svg{
  &.disabled{
    pointer-events: none;
    color: grey;
  }
}

@media (min-width:280px)  { 
  /* smartphones, iPhone, portrait 480x320 phones */
  .container-wide{
    width: 95%;
  }
}

@media (min-width:961px)  { 
  /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  
}
@media (min-width:1024px) { 
  /* big landscape tablets, laptops, and desktops */ 
}

@media (min-width:1281px) { 
  /* hi-res laptops and desktops */ 
  .container-wide{
    width: 85%;
  }
}
